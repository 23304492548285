*{
  font-family: "Lora";
  font-style: italic;
  font-weight: 500;
}

body{
  margin: 0;
  background: #eeeeee;
}
.Home{
    .Home-header {
      background-color: #eeeeee;
      display: flex;
      color: #121212;
      align-items: center;
      justify-content: space-around;
      padding: 175px 20px 135px;
      .shortDescription{
        width: 30%;
        padding: 30px;
        font-size: 20px;
        font-family: 'Lora';
      }
      .sample-image1{
        display: flex;
        width: fit-content;
        gap: 20px;
        .first-two{
          display: grid;
          gap: 20px;
          img{
            width: 405px;
            height: auto;
          }
        }
        img{
          box-shadow: 4px 4px 12px 0px #2e2e2e;
          width: 400px;
        }
      }
    }
}

.Header{
  display: flex;
  width: 100%;
  justify-content: center;
  background: #2e2e2e;
  position: fixed;
  z-index: 10;
  .pk-header-main{
    display: flex;
    background-color: #2e2e2e;
    width: 100%;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;;
    .logo-section{
        width: 125px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-left: 20px;
          .tagline{
          display: grid;
          justify-items: center;
          font-family: 'Lora';
          font-size: 12px;
          text-transform: uppercase;
          text-wrap: nowrap;
          letter-spacing: 5px;
        }
        img{
            width: 100%;
        }
    }
    .nav-section{
        display: flex;
        justify-content: center;
        gap: 18px;
        a{
          color: #fefefe;
          text-decoration: none;
          padding: 5px;
        }
    }
  }
  .mobile-menu{ 
    display: none;
  }
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2e2e;
  padding: 10px;
  bottom: 0;
  .logo-section{
    display: grid;
    justify-items: center;
    img{
      width: 50px;
    }
    .social-icons{
        display: flex;
        align-items: center;
        gap:30px;
        img{
          width: 24px;    
        }
    }
  }
}

.about, .portfolio-section, .contact-section, .pricing-services{
  background-color: #eeeeee;
  min-height: 100vh;
  padding-top: 180px;
  color: #121212;
}

.portfolio-section{
  background-color: #ffffff;
  .image-grid{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    gap: 30px;
    padding: 20px;
    img{
      box-shadow: 4px 4px 6px 0px #000000;
      border: none;
      transition: all 0.3s ease-in-out;
      &:hover{
        box-shadow: none;
      }
    }
  }
}

.about-section{
  background-color: #eeeeee;
  display: flex;
  padding: 15% 40px;
  justify-content: space-around;
  flex-direction: row;
  .about-me{
      width: 50%;
      font-family: "Lora";
      font-style: italic;
      font-weight: 500;
    .title{
      font-size: 50px;
      font-weight: bold;
      padding-bottom: 20px;
    }
  }
  .lead-img{
    display: flex;
    width: fit-content;
    justify-items: end;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Lora';
    img{
      width: 293px;
      height: 440px;
    }
  }
}

html{
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
  }
  &::-webkit-scrollbar-thumb {
    background: #676464;
    border-radius: 10px;
    &:hover{
      background: #7d7979;
    }
  }
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar::-moz-scrollbar {
  display: none;
}
.no-scrollbar::-o-scrollbar {
  display: none;
}
.no-scrollbar::-ms-scrollbar {
  display: none;
}

.hidden {
  display: none;
}

.pricesAndServices{
  background-color: #eeeeee;
  min-height: 100vh;
  padding-top: 180px;
  color: #121212;
  letter-spacing: 1px;
  span{
    font-size: 28px;
    font-weight: bold;
    font-family: 'Lora';
  }
  .details{
    padding: 20px;
    ul {
      list-style-type: none;
    }
    li{
      padding: 5px;
      font-size: large;
      font-family: 'Lora';
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 19px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-image: url("../images/arrow_9282725.png");
        background-size: cover;
      }
    }
  }
  .formtext{
    text-align: center;
    span{
      font-size: 20px;
    }
  }

  .choose_us{
    padding: 20px;
    span{
      font-size: 28px;
      font-weight: bold;
    }
    ul{
      list-style-type: none;
    }
    li{
      padding: 6px 0;
      &::before {
        content: "\2713";
        margin-right: 10px;
    }
    }
  }
  #formContainer{
    max-width: 50%;
    margin: auto;
    margin-top: 15%;
    padding: 5%;
    transition: opacity 0.5s ease;
    // opacity: 0;
    position: relative;
    .contact_form{
      display: grid;
      gap: 8px;
      padding: 40px;
      border-radius: 10px;
      border: 1px solid #2e2e2e;
      box-shadow: 3px 3px 10px 1px #2e2e2e;
      input, textarea{
        padding: 16px;
        font-family: 'Lora';
        font-size: 16px;
        border-radius: 5px;
        border: none;
        box-shadow: 1px 1px 5px;
        &:focus{
          border: none;
          outline: none;
          box-shadow: 0px 0px 3px inset;
        }
      }
      button.submit_btn{
        width: 100px;
        margin: auto;
        background-color: #ffffff;
        padding: 10px;
        border: none;
        color: #121212;
        box-shadow: 0px 2px 4px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          box-shadow: 0px 0px 4px inset;
        }
      }
    }
    &.visible{
      opacity: 1;
    }
    .toastflag{
      position: absolute;
      background-color: #121212;
      color: white;
      padding: 30px;
      opacity: 0;
      visibility: hidden;
      border-radius: 5px;
      top: 110px;
      left: 13%;
      transition: all 0.7s ease-in-out;
      &.message{
        opacity: 0.7;
        visibility: visible;
      }
    }
  }
}

.line-break {
  display: block;
  height: 6px;
  content: "";
}

.contact-section{
  .content{
    .left_section{
      padding: 20px;
      font-family: 'Sofia Sans';
      .header{
        span{
          font-size: 28px;
          font-weight: bold;
        }
      }
      .body{
        li p{
          font-family: 'Sofia Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Home{
    .Home-header {
      background-color: #eeeeee;
      display: grid;
      color: #121212;
      align-items: center;
      justify-content: space-around;
      .shortDescription{
        width: auto;
        margin: 80px 10px;
        padding: 40px;
        font-size: 20px;
        font-family: 'Lora';
      }
      .sample-image1{
        display: grid;
        width: fit-content;
        gap: 20px;
        .first-two{
          display: grid;
          gap: 20px;
          img{
            width: 100%;
            height: auto;
          }
        }
        img{
          box-shadow: 4px 4px 12px 0px #2e2e2e;
          width: 100%;
        }
      }
    }
}

.Header{
  display: flex;
  width: 100%;
  justify-content: center;
  background: #2e2e2e;
  position: fixed;
  align-items: center;
  z-index: 10;
  .pk-header-main{
    display: flex;
    background-color: #2e2e2e;
    width: 100%;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;;
    .logo-section{
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 10px;
          .tagline{
          display: grid;
          justify-items: center;
          font-family: 'Lora';
          font-size: 10px;
          text-transform: uppercase;
          text-wrap: nowrap;
          letter-spacing: 4px;
        }
        img{
            width: 100%;
        }
    }
    .nav-section{
        display: flex;
        justify-content: center;
        gap: 18px;
        display: none;
        a{
          color: #fefefe;
          text-decoration: none;
          padding: 5px;
          &:hover{
            box-shadow: -2px 1px 5px 1px #fcfcfc;
          }
        }
    }
  }
  .mobile-menu{
    display: block;
    width: 45px;
    height: 45px;
    margin: 20px;
    object-fit: contain;
    img{
      height: inherit;
      filter: invert(1);
    }
    .hamburger-menu{
      position: absolute;
      background-color: #2e2e2e;
      opacity: 0;
      visibility: hidden;
      right: 0;
      margin-top: 5%;
      width: 100vw;
      transition: opacity 0.3s ease-in-out;
      transition: height 4s ease-in-out;
      transition: all 0.5s ease;
      transform-origin: top right;
      &.open{
        opacity:  1;
        visibility: visible;
        transform: scale(1);
      }
      .menu-items{
        list-style-type: none;
        padding: 10px;
        display: grid;
        justify-items: center;
        li{
          padding: 10px;
          a{
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2e2e;
  padding: 20px;
  bottom: 0;
  .logo-section{
    display: grid;
    justify-items: center;
    img{
      width: 50px;
    }
    .social-icons{
        display: flex;
        align-items: center;
        gap:30px;
        img{
          width: 36px;    
        }
    }
  }
}

.about, .portfolio-section, .contact-section, .pricing-services{
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #121212;
}

.portfolio-section{
  background-color: #ffffff;
  .image-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    img{
      width: auto;
      box-shadow: 4px 4px 6px 0px #000000;
    }
  }
}

.about-section{
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 45% 40px 0;
  justify-content: space-around;
  align-items: center;
  .about-me{
      width: 100%;
      font-family: "Lora";
      font-style: italic;
      font-weight: 500;
    .title{
      font-size: xx-large;
      font-weight: bold;
    }
  }
  .lead-img{
    display: flex;
    width: fit-content;
    justify-items: end;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Lora';
    margin: 23% 0;
    img{
      width: 293px;
      height: 440px;
    }
  }
}
.pricesAndServices{

  .choose_us{
    padding: 20px;
    span{
      font-size: 28px;
      font-weight: bold;
    }
    ul{
      list-style-type: none;
    }
    li{
      padding: 6px 0;
      &::before {
        content: "\2713";
        margin-right: 10px;
    }
    }
  }
  #formContainer{
    max-width: none;
    margin: auto;
    margin-top: 35%;
    padding: 5%;
    transition: opacity 0.5s ease;
    // opacity: 0;
    .contact_form{
      display: grid;
      gap: 8px;
      padding: 40px;
      border-radius: 10px;
      border: 1px solid #2e2e2e;
      box-shadow: 3px 3px 10px 1px #2e2e2e;
      input, textarea{
        padding: 16px;
        font-family: 'Lora';
        font-size: 16px;
        border-radius: 5px;
        border: none;
        box-shadow: 1px 1px 5px;
        &:focus{
          border: none;
          outline: none;
          box-shadow: 0px 0px 3px inset;
        }
      }
      button.submit_btn{
        width: 100px;
        margin: auto;
        background-color: #fff;
        color: #121212;
        padding: 10px;
        border: none;
        box-shadow: 0px 2px 4px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          box-shadow: 0px 0px 4px inset;
        }
      }
    }
    &.visible{
      opacity: 1;
    }
    .toastflag{
      left: 0;
      padding: 10px;
    }
  }
}
.contact-section{
  .content{
    .left_section{
      padding: 20px;
      font-family: 'Sofia Sans';
      .header{
        span{
          font-size: 28px;
          font-weight: bold;
        }
      }
      .body{
        li p{
          font-family: 'Sofia Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
}

}